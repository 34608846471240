import { combineReducers } from 'redux';
import account from './account';
import activities from './activities';
import addresses from './addresses';
import agreements from './agreements';
import alerts from './alerts';
import cashback from './cashback';
import changeRequests from './changeRequests';
import linkedAccounts from './linkedAccounts';
import countries from './countries';
import dataDownloads from './dataDownloads';
import dataRequests from './dataRequests';
import emailConfirmation from './emailConfirmation';
import emails from './emails';
import errors from './errors';
import featureFlags from './featureFlags';
import help from './help';
import ids from './ids';
import logins from './logins';
import password from './password';
import preferences from './preferences';
import session from './session';
import states from './states';
import twofa from './twofa';
import user from './user';
import websites from './websites';
import workProfile from './workProfile';

const rootReducer = combineReducers({
  account,
  activities,
  addresses,
  agreements,
  alerts,
  cashback,
  changeRequests,
  countries,
  dataDownloads,
  dataRequests,
  emailConfirmation,
  emails,
  errors,
  featureFlags,
  help,
  ids,
  linkedAccounts,
  logins,
  password,
  preferences,
  session,
  states,
  twofa,
  user,
  websites,
  workProfile,
});

export default rootReducer;
