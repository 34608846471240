import Logout from '../pages/Logout';
import Home from '../pages/Home';
import Overview from '../pages/Overview';
import Profile from '../pages/Profile';
import Signin from '../pages/Signin';
import Wallet from '../pages/Wallet';
import EmailConfirm from '../pages/EmailConfirm';
import Privacy from '../pages/Privacy';
import Help from '../pages/Help';
import Four04 from '../pages/Four04';
import memoize from '../utils/memoize';
import LinkedAccounts from '../pages/LinkedAccounts';
import { TRANSLATIONS_SUPPORTED } from '../constants';

const routes = [
  {
    component: Logout,
    exact: true,
    name: 'Logout',
    path: '/logout',
    requireAuth: false,
  },
  {
    component: Home,
    exact: true,
    name: 'Home',
    path: '/',
    requireAuth: true,
  },
  {
    component: Overview,
    name: 'Overview',
    navTabs: true,
    path: '/overview',
    requireAuth: true,
  },
  {
    component: Profile,
    name: 'Profile',
    navTabs: true,
    path: '/profile',
    requireAuth: true,
  },
  {
    component: Signin,
    name: 'Sign In & Security',
    navTabs: true,
    path: '/signin',
    requireAuth: true,
    to: '/signin/security',
  },
  {
    component: LinkedAccounts,
    name: 'Linked Accounts',
    navTabs: true,
    path: '/linkages',
    requireAuth: true,
    requireLinkedAccounts: true,
  },
  {
    component: Wallet,
    name: 'My Wallet',
    navTabs: true,
    path: '/wallet',
    requireAuth: true,
    to: '/wallet/ids',
  },
  {
    component: EmailConfirm,
    name: 'Email Confirmation',
    navTabs: false,
    path: '/email-confirmation/:id',
  },
  {
    component: Privacy,
    name: 'Privacy',
    navTabs: false,
    path: '/privacy',
  },
  {
    component: Privacy,
    name: 'Privacy',
    navTabs: false,
    path: '/ccpa',
  },
  {
    component: Help,
    name: 'Contact Support',
    navTabs: true,
    path: '/help',
    requireAuth: true,
    to: '/help/contact',
  },
  {
    component: Four04,
  },
];

const pathIndex = TRANSLATIONS_SUPPORTED ? 1 : 0;

const unauthedRoutes = routes
  .filter((route) => !route.requireAuth && route.name)
  .map((route) => route.path.split('/:')[pathIndex]);

export const isUnauthedRoute = memoize((path) =>
  unauthedRoutes.some((route) => path.indexOf(route) === pathIndex),
);

export default routes;
