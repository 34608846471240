import axios from 'axios';
import { URL_DATA } from '../constants/urls';

export const FETCH_SELFIE_STATUS_BEGIN = 'FETCH_SELFIE_STATUS_BEGIN';
export const FETCH_SELFIE_STATUS_SUCCESS = 'FETCH_SELFIE_STATUS_SUCCESS';
export const FETCH_SELFIE_STATUS_FAILURE = 'FETCH_SELFIE_STATUS_FAILURE';

export const DELETE_SELFIE_BEGIN = 'DELETE_SELFIE_BEGIN';
export const DELETE_SELFIE_SUCCESS = 'DELETE_SELFIE_SUCCESS';
export const DELETE_SELFIE_FAILURE = 'DELETE_SELFIE_FAILURE';

export const fetchDataRequestByType = (type) => async (dispatch) => {
  dispatch({ type: FETCH_SELFIE_STATUS_BEGIN });

  try {
    const { data } = await axios.get(`${URL_DATA}/${type}`);

    dispatch({ payload: data, type: FETCH_SELFIE_STATUS_SUCCESS });
  } catch (error) {
    dispatch({
      payload: error && error.response && error.response.statusText,
      type: FETCH_SELFIE_STATUS_FAILURE,
    });
  }
};

export const deleteRequestByType = (type) => async (dispatch) => {
  dispatch({ type: DELETE_SELFIE_BEGIN });

  try {
    const { data } = await axios.delete(`${URL_DATA}/${type}`);

    dispatch({ payload: data, type: DELETE_SELFIE_SUCCESS });

    return true;
  } catch (error) {
    dispatch({
      payload: error && error.response && error.response.statusText,
      type: DELETE_SELFIE_FAILURE,
    });
  }
};
